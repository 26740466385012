<template>
    <base-card class="card general-detail">
        <div class="card-title">
            <h2 class="fsb-typo-heading-2">{{ t('generalInfo') }}</h2>
            <!-- Commented out because the edit option is not functional at the moment -->
            <!-- <icon-button icon-type="edit" variant="secondary" /> -->
        </div>
        <div class="pos-details">
            <div class="detail-item">
                <span class="fsb-typo-para-regular"> {{ t('denomination') }} </span>
                <span class="fsb-typo-btn-principal var(--grayscale-gray-dark)">{{ pos.payment_info.point_text }}</span>
            </div>
            <div class="detail-item">
                <span class="fsb-typo-para-regular">{{ t('siret') }}</span>
                <span class="fsb-typo-btn-principal">{{ pos.siret ? pos.siret : '-' }}</span>
            </div>
            <div class="detail-item">
                <span class="fsb-typo-para-regular">{{ t('address') }} </span>
                <span class="fsb-typo-btn-principal">{{ pos.payment_info.address }}</span>
            </div>
            <div class="detail-item">
                <span class="fsb-typo-para-regular">{{ t('zipcode') }} </span>
                <span class="fsb-typo-btn-principal">{{ pos.zipcode }}</span>
            </div>
            <div class="detail-item">
                <span class="fsb-typo-para-regular">{{ t('city') }}</span>
                <span class="fsb-typo-btn-principal">{{ pos.city }}</span>
            </div>
        </div>
        <div class="general-conditions">
            <div class="checkbox-container">
                <input type="checkbox" id="termsCheckbox" class="terms-checkbox" checked disabled />
                <label for="termsCheckbox" class="fsb-typo-para-regular">
                    {{ t('iAgreeToPay') }}
                    <a :href="cgauPdf" class="fsb-typo-link-regular" target="_blank" rel="noopener noreferrer" :download="getFilename(cgauPdf)">{{ t('CGAU') }}</a>
                </label>
            </div>
        </div>
        <div class="privacy-policy">
            <span class="fsb-typo-para-regular"> {{ t('privacyPolicy') }} </span>
            <a :href="confidialityPdf" class="fsb-typo-link-regular" target="_blank" rel="noopener noreferrer" :download="getFilename(confidialityPdf)">{{ t('privacyPolicyPdf') }}</a>
        </div>
    </base-card>
</template>

<script lang="ts" setup>
    import { PropType } from 'vue';
    import { useI18n } from 'vue-i18n';
    const { t } = useI18n();
    const cgauPdf: string = process.env.VUE_APP_CGAU as string;
    const confidialityPdf: string = process.env.VUE_APP_CONFIDENTIALITY as string;
    const getFilename = (url: string) => {
        return url?.substring(url.lastIndexOf('/') + 1);
    };
    interface Pos {
        name: string;
        signatory_name: string;
        email: string;
        company_name: string;
        siret: string;
        address: string;
        billing_address: string;
    }

    const props = defineProps({
        pos: {
            type: Object as PropType<Pos>,
            required: true,
        },
    });
</script>

<style lang="scss" scoped>
    .checkbox-container {
        display: flex;
        align-items: center;
        display: inline;
    }
    .terms-checkbox {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        border: 1px solid #d1d1d1;
        border-radius: 3px;
        background-color: #fff;
        cursor: pointer;
        vertical-align: middle;
    }

    .terms-checkbox:checked {
        background-color: #b3b3b3;
        &:after {
            content: '✓';
            transform: translate(-50%, -50%);
            color: white;
            font-size: 20px;
            z-index: 100;
        }
    }

    .terms-checkbox:checked {
        background-color: #b3b3b3;
    }

    .fsb-typo-para-regular {
        display: inline;
    }
    .fsb-typo-btn-principal {
        color: var(--grayscale-gray-dark);
    }
    .general-detail {
        .general-conditions {
            display: flex;
            margin-bottom: 8px;
        }

        .card-title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
        }

        .pos-details {
            width: 100%;
            .detail-item {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                width: 100%;

                .fsb-typo-para-regular {
                    color: #333;
                    width: 40%;
                }
            }
        }
        .fsb-typo-para-regular {
            color: #707070;
        }
    }
</style>
