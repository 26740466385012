<template>
    <div class="pdv-detail">
        <div class="header-container">
            <icon-button icon-type="chevron-left" variant="white" @click="navigateToPos" v-if="apiStore.posDetails.length > 1" />
            <span class="fsb-typo-heading-1">{{ pos.point_name }}</span> <span class="fsb-typo-para-regular">{{ pos.point_code }}</span>
        </div>
        <div class="card-container">
            <GeneralDetail :pos="pos" />
            <RepiquageDetail v-if="filteredbyBrandReplication && filteredbyBrandReplication.length" :pos="pos" />
            <MandateDetail v-if="filteredByBrandMandates && filteredByBrandMandates.length" :mandate="pos" />
            <base-card class="card abonnement-actif">
                <div class="subscription-header">
                    <subscription-status :active="pos?.subscription == 'active'"></subscription-status>
                    <ui-button
                        v-if="pos?.subscription !== 'active'"
                        id="subRoleTooltipTarget"
                        :disabled="isActivationDisabled"
                        style="pointer-events: auto"
                        variant="secondary"
                        :on-click="
                            () => {
                                if (!isActivationDisabled) {
                                    getPosSubscriptionAmount();
                                }
                            }
                        "
                        >{{ $t('activate') }}</ui-button
                    >
                </div>
            </base-card>
            <InvoiceDetail :payment-info="pos.payment_info" />
            <InvoiceContact :payment-info="pos.payment_info" />
        </div>
        <pos-activation v-if="showModal" v-model:show-modal="showModal" :brand="brand" :pos-list="formattedInactiveValue" @close-modal="onCloseModal" :allow-close="true"></pos-activation>
        <base-tooltip v-if="isActivationDisabled" target="subRoleTooltipTarget" placement="bottom">
            <div class="text-tooltip fsb-typo-para-regular">{{ t('notAllowedForSubRole') }}</div>
        </base-tooltip>
    </div>
</template>

<script lang="ts" setup>
    import { ref, computed, inject, Ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useApiStore } from '@/store/store-index';
    import InvoiceDetail from './invoiceDetail.vue';
    import GeneralDetail from './generalDetail.vue';
    import MandateDetail from './mandateDetail.vue';
    import RepiquageDetail from './repiquageDetail.vue';
    import InvoiceContact from './invoiceContact.vue';
    import { useI18n } from 'vue-i18n';
    import { storeToRefs } from 'pinia';
    import { User } from '@adcleek/db-types';
    const snack: any = inject('snackBar');

    const apiStore = useApiStore();
    const router = useRouter();
    const route = useRoute();
    const navigateToPos = () => {
        router.push('/pos');
    };
    const pointCode = ref(route.params.pointCode as string);
    const { posDetails } = storeToRefs(apiStore);
    const activatingPos = ref(false);
    const payment = apiStore.posDetails;
    const mandateRequired = apiStore.mandateMedia;
    const replicationRequired = apiStore.brandInfo;
    const { t } = useI18n({ useScope: 'global' });
    const filteredByBrandMandates = computed(() => {
        const flattenedMandateRequired = mandateRequired.flat();
        const pos = payment.find((item) => item.point_code === pointCode.value);
        const mediaWithMandate = flattenedMandateRequired.filter((item) => item.brand === pos.brand && item.requireMandat);
        return mediaWithMandate.length ? mediaWithMandate : null;
    });

    const filteredbyBrandReplication = computed(() => {
        const pos = payment.find((item) => item.point_code == pointCode.value);
        if (!pos) return null;

        const brandWithReplication = replicationRequired.filter((item) => item.code === pos.brand && item.details.replication === true);

        return brandWithReplication.length ? brandWithReplication : null;
    });

    const pos = computed(() => {
        return posDetails.value.find((item) => item.point_code == pointCode.value);
    });

    const isActivationDisabled = computed(() => {
        if (pos.value?.brand) {
            const userRole = apiStore.userRole(pos.value.brand);
            return userRole !== 1;
        }
        return true;
    });

    const showModal = ref(false);
    const brand = computed(() => pos.value.brand);
    const formattedInactiveValue = computed(() => {
        return [
            {
                name: pos.value.point_name,
                city: pos.value.city,
                code: pos.value.point_code,
                ...posSubscriptionAmount.value,
            },
        ];
    });

    const posSubscriptionAmount: Ref<User.PosActivationPriceCtl | null> = ref(null);
    const getPosSubscriptionAmount = async () => {
        posSubscriptionAmount.value = (await apiStore.get(`/user/pos-activation-price/${brand.value}/${pos.value.point_code}`)).data;
        showModal.value = true;
    };
    const onCloseModal = async (activationCount: number) => {
        showModal.value = false;
        if (activationCount === 0) return;
        snack.open('success', t('posActivated', 1), 3.6);
        activatingPos.value = true;
        await apiStore.setPosDetails();
        activatingPos.value = false;
    };
</script>

<style lang="scss" scoped>
    .pdv-detail {
        .header-container {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .card-container {
            column-count: 2;
            column-gap: 36px;
            margin-top: 24px;
            width: 100%;
        }

        .subscription-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
        }
        .card {
            background: #ffffff;
            border-radius: 16px;
            padding: 24px;
            text-align: left;
            display: inline-block;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 36px;
            break-inside: avoid-column;
            page-break-inside: avoid;
            box-shadow: none !important;
            border: none !important;
        }
        .card:last-child {
            margin-bottom: 0;
        }
    }
    .subscription {
        color: #707070;
    }
    .fsb-typo-heading-3 {
        text-align: left;
        margin-left: 0;
        padding-left: 0;
    }
</style>
