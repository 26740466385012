<template>
    <div class="subscription-status">
        <div class="subscription-status__point"></div>
        <div>
            {{ active ? $t('activeSubscription') : $t('inactiveSubscription') }}
            <div v-if="date" class="subscription-status__date fsb-typo-para-regular">{{ $t('activeSubscriptionInfo', { date: formattedDate }) }}</div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';

    const props = defineProps<{ active: boolean; date?: string }>();

    const pointColor = computed(() => {
        return props.active ? 'var(--secondary-vert)' : 'var(--secondary-rouge)';
    });

    const formattedDate = computed(() => {
        // 2024-10-07T15:44:33.000Z

        let match = props.date?.match(/(\d{4}-\d{2}-\d{2})/);
        if (match) {
            let splittedDate = match[0].split('-');
            return splittedDate.reverse().join('/');
        }
        return '#error';
    });
</script>

<style lang="scss">
    .subscription-status {
        display: flex;
        align-items: center;
        &__point {
            margin-right: 8px;
            width: 12px;
            height: 12px;
            border-radius: 4px;
            background-color: v-bind(pointColor);
        }
        &__date {
            margin-top: 2px;
            color: var(--grayscale-gray-medium) !important;
        }
    }
</style>
