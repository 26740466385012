<template>
    <div class="users-list">
        <HeaderSection />
        <ActionButtons :selectedInvoices="selectedInvoices" :showCustomDate="showCustomDate" @toggleDatePicker="toggleDatePicker" @downloadInvoice="downloadInvoice" />
        <InvoiceTable
            :selectedFilter="selectedFilter"
            :selectedInvoices="selectedInvoices"
            @updateSelectedInvoices="updateSelectedInvoices"
            @openInvoice="openInvoice"
            @downloadInvoice="downloadInvoice"
        />
        <InvoiceModal :modalShow="modalShow" :pdfsrc="pdfsrc" :modalKey="modalKey" :currentItem="currentItem" @close="closeModal" @downloadInvoice="downloadInvoice" />
    </div>
</template>

<script setup>
    import { ref, inject } from 'vue';
    import HeaderSection from './HeaderSection.vue';
    import ActionButtons from './ActionButtons.vue';
    import InvoiceTable from './InvoiceTable.vue';
    import InvoiceModal from './InvoiceModal.vue';
    import { useI18n } from 'vue-i18n';
    import * as FileSaver from 'file-saver';
    import { useApiStore } from '@/store/store-index';

    const { t } = useI18n({ useScope: 'global' });
    const snack = inject('snackBar');

    if (!snack) {
        throw new Error('snackBar not provided');
    }

    const selectedInvoices = ref([]);
    const apiStore = useApiStore();
    const pdfsrc = ref('');
    const modalShow = ref(false);
    const modalKey = ref(0);
    const currentItem = ref(null);

    const updateSelectedInvoices = (selectedOptions) => {
        selectedInvoices.value = selectedOptions;
    };

    const fetchInvoiceBlob = async (invoiceItem) => {
        try {
            const response = await apiStore.post('/user/download-invoice', { invoices: invoiceItem }, { responseType: 'blob' });
            if (response.data instanceof Blob) {
                return response.data;
            }
            throw new Error('Response is not a blob');
        } catch (err) {
            snack.open('alert', t('Échec du téléchargement de la facture.'));
            throw err;
        }
    };

    const openInvoice = async (item) => {
        currentItem.value = item;
        try {
            const blob = await fetchInvoiceBlob(item);
            const objectUrl = URL.createObjectURL(blob);
            pdfsrc.value = objectUrl;
            modalShow.value = true;
        } catch (err) {
            snack.open('alert', t('Échec du téléchargement de la facture.'));
        }
    };

    const closeModal = () => {
        if (pdfsrc.value) {
            URL.revokeObjectURL(pdfsrc.value);
            pdfsrc.value = '';
        }
        modalShow.value = false;
    };

    const downloadInvoice = async (items) => {
        try {
            const blob = await fetchInvoiceBlob(items);
            const contentType = blob.type;
            let fileExtension = 'pdf';
            if (contentType === 'application/zip') {
                fileExtension = 'zip';
            }

            let fileName = `invoices.${fileExtension}`;

            if (fileExtension === 'pdf') {
                const invoiceName = Array.isArray(items) ? items.map((item) => item.details.invoiceName).join(', ') : items.item.details.invoiceName;
                fileName = `${invoiceName}.pdf`;
            }
            FileSaver.saveAs(blob, fileName);
            snack.open('success', t('Téléchargement de la facture réussi.'));
        } catch (err) {
            snack.open('alert', t('Échec du téléchargement de la facture.'));
        }
    };
</script>

<style scoped>
    .users-list {
        width: 100%;
    }
</style>
