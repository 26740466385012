<template>
    <div>
        <div id="security">
            <h1 class="fsb-typo-heading-1">{{ t('security') }}</h1>
            <p class="fsb-typo-para-regular subtitle">{{ t('passwordEdit') }}</p>

            <password-input
                :key="oldPasswordKey"
                class="input"
                id="input-oldPassword"
                :label="t('oldPassword')"
                :invalid="oldPasswordRequired"
                :placeholder="t('oldPasswordPlaceholder')"
                :default-value="form.oldPassword"
                required
                @update:value="updateFormField('oldPassword', $event)"
            />
            <p v-if="oldPasswordRequired" class="error-message">{{ t('thisFieldIsRequired') }}</p>

            <password-input
                :key="newPasswordKey"
                class="input"
                id="input-newPassword"
                :label="t('newPassword')"
                :invalid="newPasswordRequired"
                :placeholder="t('newPasswordPlaceholder')"
                :default-value="form.password"
                required
                @update:value="updateFormField('password', $event)"
            />
            <p v-if="newPasswordRequired" class="error-message">{{ t('thisFieldIsRequired') }}</p>

            <password-input
                :key="confirmPasswordKey"
                class="input confirm-password"
                id="input-confirmPassword"
                :label="t('confirmPassword')"
                :invalid="confirmPasswordInvalid"
                :placeholder="t('confirmPasswordPlaceholder')"
                :default-value="form.confirmPassword"
                required
                @update:value="updateFormField('confirmPassword', $event)"
            />
            <p v-if="confirmPasswordInvalid" class="error-message">{{ confirmPasswordMessage }}</p>

            <ul class="password-rules" v-if="form.password.length > 0">
                <li
                    v-for="(ruleWithMessage, index) in validationRulesWithMessages"
                    :key="index"
                    :class="{ 'valid-rule': ruleWithMessage.condition.value, 'invalid-rule': !ruleWithMessage.condition.value }"
                >
                    <ad-icon :icon="ruleWithMessage.condition.value ? 'check' : 'add'" :variant="ruleWithMessage.condition.value ? 'success' : 'disabled'" size="small"></ad-icon>
                    <p class="fsb-typo-para-regular validation">{{ ruleWithMessage.message }}</p>
                </li>
            </ul>
            <fsb-alert v-if="wrongOldPassword" variant="danger" icon="info">{{ t("oldPasswordNoMatch") }}</fsb-alert>

            <div class="d-flex justify-content-end">
                <ui-button class="button-reset" v-if="showResetButton" :label="t('cancel')" variant="white" @click="resetForm" />
                <ui-button class="button-update" :disabled="!validForm" :label="t('confirm')" variant="primary" @click="updatePassword(user)" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { reactive, ref, computed, watch, inject } from 'vue';
import { useApiStore } from '@/store/store-index';
import { useI18n } from 'vue-i18n';

const user = computed(() => apiStore.users);
const apiStore = useApiStore();
const { t } = useI18n();
const snack: any = inject('snackBar');
const passwordInvalid = ref(false);
const oldPasswordRequired = ref(false);
const newPasswordRequired = ref(false);
const confirmPasswordInvalid = ref(false);
const showResetButton = ref(false);
const wrongOldPassword = ref(false);
const form = reactive({
    oldPassword: '',
    password: '',
    confirmPassword: '',
});

const oldPasswordKey = ref(0);
const newPasswordKey = ref(0);
const confirmPasswordKey = ref(0);

const validationConditions = [
    (password: string) => /[A-Z]/.test(password),
    (password: string | any[]) => password.length >= 12,
    (password: string) => /\d/.test(password),
    (password: string) => /[^\w\s]/.test(password),
];

const validationMessages = [t('oneUpperCase'), t('12Characters'), t('oneDigit'), t('specialCharacter')];

const validationRulesWithMessages = validationConditions.map((condition, index) => ({
    condition: computed(() => condition(form.password)),
    message: validationMessages[index],
}));

const isNewPasswordValid = computed(() => validationRulesWithMessages.every((rule) => rule.condition.value));

const validForm = computed(() => {
    const isOldPasswordFilled = form.oldPassword.length > 0;
    const isPasswordConfirmed = form.password === form.confirmPassword;
    return isOldPasswordFilled && isNewPasswordValid.value && isPasswordConfirmed;
});

const resetForm = () => {
    form.password = '';
    form.confirmPassword = '';
    form.oldPassword = '';
    passwordInvalid.value = false;
    confirmPasswordInvalid.value = false;
    oldPasswordKey.value++;
    newPasswordKey.value++;
    confirmPasswordKey.value++;
};

const checkPasswordsMatch = () => {
    passwordInvalid.value = form.password !== form.confirmPassword && form.confirmPassword.length > 0;
};

const updateFormField = (fieldName: string, value: string) => {
    form[fieldName] = value.trim();
    if (fieldName === 'password' || fieldName === 'confirmPassword') {
        checkPasswordsMatch();
    }
};

const confirmPasswordMessage = computed(() => (form.confirmPassword.length === 0 ? t('thisFieldIsRequired') : form.password !== form.confirmPassword ? t('passwordsDoNotMatch') : ''));

watch(
    () => [form.password, form.confirmPassword, form.oldPassword],
    ([newPassword, newConfirmPassword, newOldPassword]) => {
        if (newPassword.length > 0  || newConfirmPassword.length > 0  || newOldPassword.length > 0 ) wrongOldPassword.value = false 
        newPasswordRequired.value = newPassword.length === 0 && (newOldPassword.length > 0 || newConfirmPassword.length > 0);
        confirmPasswordInvalid.value = newPassword.length > 0 && newPassword !== newConfirmPassword;
        oldPasswordRequired.value = newPassword.length > 0 && newConfirmPassword.length > 0 && newOldPassword.length === 0;
        showResetButton.value = newPassword.length > 0 || newConfirmPassword.length > 0 || newOldPassword.length > 0;
    },
    { immediate: true }
);

const updatePassword = async (user: { email: string }) => {
    const payload = {
        email: user.email,
        password: form.password,
        confirmPassword: form.confirmPassword,
        oldPassword: form.oldPassword,
    };

    try {
        await apiStore.post('/user/update-password', payload);
        snack.open('success', t('changedPassword'));
      } catch (error) {
        resetForm()
        if (error.response && error.response.status == 403) {
          wrongOldPassword.value = true;
        }
        snack.open('alert',  t("errorUpdateProfile"));
      }
};
</script>

<style scoped>
.fsb-typo-heading-1 {
    margin-bottom: 6px;
}
.fsb-typo-para-regular {
    color: #707070;
    &.subtitle {
        margin-bottom: 25px !important;
    }
    &.validation {
        margin: 0;
        line-height: 1.2em;
    }
}
.input {
    margin-bottom: 12px !important;
    &.confirm-password {
        margin-top: 12px !important;
    }
}
#security {
    max-width: 400px;
}
.button-reset,
.button-update {
    margin-top: 20px;
}
.button-reset {
    margin-right: 10px;
}
.password-rules {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 0;
    margin-top: 10px;
}
.password-rules li {
    display: flex;
    align-items: center;
    gap: 5px;
}
.valid-rule .fsb-typo-para-regular,
.valid-rule {
    color: #02b15d;
}
.error-message {
    color: red;
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 10px;
}
</style>
