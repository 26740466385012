<template>
    <layout-with-fixed-nav-bar :show-nav-bar="true" :show-footer="true" nav-bar-width="200px" padding="40px">
        <template #nav-bar>
            <admin-nav-bar :defaultBrandsHeader="false" :sections="USER_SECTIONS" default-active-section="profil">
                <template #router-links> </template>
            </admin-nav-bar>
        </template>
        <template #main-content>
            <router-view v-slot="{ Component }">
                <keep-alive>
                    <transition name="fadeView" mode="out-in">
                        <component :is="Component" :key="route.path" />
                    </transition>
                </keep-alive>
            </router-view>
        </template>
        <template #footer>
            <FooterContent />
        </template>
    </layout-with-fixed-nav-bar>
</template>
<style lang="scss"></style>

<script lang="ts" setup>
    import { defineProps, onMounted } from 'vue';
    import { useApiStore } from './store/api';
    import { RouterView, useRoute } from 'vue-router';
    import router from '@/router/index';
    import { useI18n } from 'vue-i18n';
    import FooterContent from '@/components/layouts/FooterContent.vue';
    import Smartlook from 'smartlook-client';

    const { t } = useI18n({ useScope: 'global' });

    const apiStore = useApiStore();
    const route = useRoute();

    const props = defineProps({
        defaultBrandsHeader: Boolean,
    });

    onMounted(() => {
        apiStore.init();
        try {
            if (process.env.VUE_APP_SHOW_SMARTLOOK && JSON.parse(process.env.VUE_APP_SHOW_SMARTLOOK)) {
                if (process.env.VUE_APP_SMARTLOOK_KEY) {
                    Smartlook.init(process.env.VUE_APP_SMARTLOOK_KEY);
                }
            }
        } catch (error) {
            Smartlook.error(error as Error);
            console.error('Error initializing Smartlook:', error);
        }
    });

    const USER_SECTIONS = [
        {
            id: 'profil',
            name: t('profil'),
            icon: 'profile',
            onClick: () => router.push({ name: 'profile' }),
        },
        {
            id: 'password',
            name: t('security'),
            icon: 'security',
            onClick: () => router.push({ name: 'password' }),
        },
        {
            id: 'invoices',
            name: t('invoices'),
            icon: 'invoice',
            onClick: () => router.push({ name: 'invoices' }),
        },
        {
            id: 'pos',
            name: t('pdvs'),
            icon: 'pos',
            onClick: () => router.push({ name: 'pos' }),
        },
    ];
</script>
