import { defineStore } from 'pinia';
import { axiosInstance } from '@/axiosInstance';
import { useAuthStore } from 'vue3-lmp-plugin';

interface User {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    associes?: any[];
    posBrand: any[];
}

interface BrandInfo {
    brand: string;
    point_code: string;
    point_name: string;
    details: any;
}

export const useApiStore = defineStore('useApiStore', {
    state: () => ({
        users: {} as User,
        invoices: [] as any[],
        posDetails: [] as any[],
        associes: [] as any[],
        posBrand: [] as any[],
        mandateMedia: [] as any[],
        brandInfo: [] as BrandInfo[],
    }),
    getters: {
        allInvoices: (state) => state.invoices,
        allPosDetails: (state) => state.posDetails,
        userRole: () => {
            return (brand: string): number => {
                const authStore = useAuthStore();
                const currentUserBrandRights = authStore.user.brands[brand];
                let role = 0;

                if (currentUserBrandRights) {
                    const currentPurchaseRights = currentUserBrandRights.find((right: { role: number; service: string }) => right.service === 'purchase');
                    if (currentPurchaseRights?.rights?.role) {
                        role = currentPurchaseRights.rights.role;
                    }
                }

                return role as number;
            };
        },
    },
    actions: {
        async setPosDetails() {
            this.posDetails = (await this.get('/user/pos-details')).data;
        },
        async resetUser() {
            try {
                const response = await this.get('/user/user-info');
                this.users = response.data;
            } catch (err) {
                console.error(err);
            }
        },
        async get(url: string): Promise<any> {
            return axiosInstance.get(url);
        },
        async post(url: string, payload: any, headers?: any): Promise<any> {
            return axiosInstance.post(url, payload, headers);
        },
        async patch(url: string, payload: any): Promise<any> {
            return axiosInstance.patch(url, payload);
        },
        async init() {
            try {
                const [userInfoResponse, userInvoicesResponse, posInfoResponse, mandateStatus] = await Promise.allSettled([
                    this.get('/user/user-info'),
                    this.get('/user/user-invoices'),
                    this.get('/user/pos-details'),
                    this.get('/user/mandate-status'),
                ]);

                if (userInfoResponse.status === 'fulfilled') {
                    this.users = userInfoResponse.value.data;

                    this.associes = userInfoResponse.value.data.associes || [];
                } else {
                    console.error('Failed to fetch user info:', userInfoResponse.reason);
                }

                if (userInvoicesResponse.status === 'fulfilled') {
                    this.$patch((state) => {
                        state.invoices = userInvoicesResponse.value.data;
                    });
                } else {
                    console.error('Failed to fetch user invoices:', userInvoicesResponse.reason);
                }

                if (posInfoResponse.status === 'fulfilled') {
                    this.posDetails = posInfoResponse.value.data;
                } else {
                    console.error('Failed to fetch POS details:', posInfoResponse.reason);
                }

                if (mandateStatus.status === 'fulfilled') {
                    // Update mandate status logic here if needed
                } else {
                    console.error('Failed to fetch mandate status:', mandateStatus.reason);
                }

                if (this.users && Object.keys(this.users).length > 0) {
                    await this.fetchMandateMedia();
                    await this.fetchBrandInfo();
                }
            } catch (err) {
                console.error('Unexpected error:', err);
            }
        },
        async updateProfile(payload: User) {
            try {
                const response = await this.patch('/user/update-info', payload);
                this.users = response.data.data;
                return { success: true, data: response.data };
            } catch (error) {
                return { success: false, error };
            }
        },
        async fetchMandateStatus() {
            try {
                const response = await this.get('/user/mandate-status');
            } catch (error) {
                console.error('Failed to fetch mandate statuses:', error);
            }
        },
        async fetchBrandInfo(): Promise<void> {
            const uniqueBrands: string[] = [...new Set(this.users.posBrand.map((user) => user.brand))];
            const brandRequests: Promise<any>[] = uniqueBrands.map((brand) => this.get(`/user/brands/${brand}`));
            const responses = await Promise.all(brandRequests);
            this.brandInfo = responses.map((response) => response.data);
        },
        async fetchMandateMedia(): Promise<void> {
            const uniqueBrands: string[] = [...new Set(this.users.posBrand.map((user) => user.brand))];
            const brandRequests: Promise<any>[] = uniqueBrands.map((brand) => this.get(`/medias/available-medias/mandate/${brand}`));
            try {
                const responses = await Promise.all(brandRequests);
                this.mandateMedia = responses.map((response) => response.data);
            } catch (error) {
                console.error('Failed to fetch mandate media for brands:', error);
            }
        },
    },
});
