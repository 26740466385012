<template>
    <div class="container">
        <h1>Utilisateurs</h1>
        <div class="row gutters-sm">
            <div class="col-md-8">
                <b-button style="margin-bottom: 20px" v-b-modal.modal-alias> Ajouter un utilisateur </b-button>
                <div class="card">
                    <div>
                        <b-modal id="modal-alias" title="Créer un compte associé" @ok="addAlias(alias, selectedPos)" ok-title="Enregistrer">
                            Renseignez les informations suivantes pour ajouter un compte associé.
                            <b-form-group label="Nom">
                                <b-form-input id="input-firstname" v-model="alias.lastname" type="text" required></b-form-input>
                            </b-form-group>
                            <b-form-group label="Prenom">
                                <b-form-input id="input-firstname" v-model="alias.firstname" type="text" required></b-form-input>
                            </b-form-group>
                            <b-form-group label="email">
                                <b-form-input id="input-firstname" v-model="alias.email" type="text" required></b-form-input>
                            </b-form-group>
                            <div>
                                <label for="book">Sélectionner un point de vente:</label>
                                <b-form-select v-model="selectedPos" multiple :options="users.point_code" :select-size="4" :key="users.point_code"> </b-form-select>
                                <div class="mt-3">
                                    Selected: <strong>{{ selectedPos }}</strong>
                                </div>
                            </div>
                        </b-modal>
                        <b-table ref="table" responsive :items="associe" :fields="fields">
                            <template v-slot:cell(selected)="row">
                                <b-form-group>
                                    <input type="checkbox" v-model="row.item.selected" />
                                </b-form-group>
                            </template>
                            <template v-slot:cell(view)="row">
                                <font-awesome-icon icon="pencil" style="margin-right: 10px" v-b-modal.modal-editAlias />
                                <b-modal id="modal-editAlias" title="Modifier un compte associé" @ok="editAlias(row.item)" ok-title="Enregistrer">
                                    <b-form-group label="Nom">
                                        <b-form-input id="input-lastname" :value="row.item.lastname" v-model="row.item.lastname" type="text" required></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Prenom">
                                        <b-form-input id="input-firstname" :value="row.item.firstname" v-model="row.item.firstname" type="text" required></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="email">
                                        <b-form-input id="input-firstname" :value="row.item.email" v-model="row.item.email" type="text" required></b-form-input>
                                    </b-form-group>
                                    <div>
                                        <label for="book">Sélectionner un point de vente:</label>
                                        <b-form-select v-model="selectedPos" multiple :options="users.point_code" :select-size="4" :key="users.point_code"> </b-form-select>
                                        <div class="mt-3">
                                            Selected: <strong>{{ selectedPos }}</strong>
                                        </div>
                                    </div>
                                </b-modal>
                                <font-awesome-icon icon="trash-can" style="color: var(--primary-color)" @click="deleteAlias(row.item)" />
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { useApiStore } from '@/store/store-index';
    import Request from '@/interfaces/common-request';

    export default defineComponent({
        setup() {
            const apiStore = useApiStore();
            return {
                apiStore,
            };
        },
        data() {
            return {
                selectedPos: [],
                alias: {},
                fields: [
                    {
                        key: 'firstname',
                        label: 'Nom',
                    },
                    {
                        key: 'email',
                        label: 'Email',
                    },
                    {
                        key: 'status',
                        label: 'Role',
                    },
                    {
                        key: 'point_code',
                        label: 'Points de Vente',
                    },
                    {
                        key: 'view',
                        label: 'Actions',
                    },
                ],
            };
        },
        computed: {
            users() {
                let users: any = this.apiStore.users;
                let pos: any = [];
                for (let item of this.apiStore.posBrand) {
                    pos.push(item);
                }

                users['point_code'] = pos;
                return users;
            },
            associe() {
                let alias: any = [];
                this.apiStore.associes.forEach((element: any) => {
                    alias.push(element.user);
                });
                return alias;
            },
        },
        methods: {
            deleteAlias(user: { firstname: any; lastname: any; email: any }) {
                type Alias = {
                    email: any;
                    point_code: any;
                };

                let alias: Alias = {
                    email: user.email,
                    point_code: this.selectedPos,
                };

                (this.apiStore.post('/user/delete-alias', alias, null) as Promise<Request>)
                    .then((res: Request) => {
                        console.log(res.data);
                    })
                    .catch((err: Error) => {
                        console.error(err);
                    })
                    .finally(() => {
                        console.log('done');
                    });

                return user;
            },
            editAlias(item: any): void {
                type Alias = {
                    firstname: any;
                    lastname: any;
                    email: any;
                    point_code: any;
                };

                let alias: Alias = {
                    firstname: item.firstname,
                    lastname: item.lastname,
                    email: item.email,
                    point_code: this.selectedPos,
                };

                (this.apiStore.patch('/edit-alias', alias) as Promise<Request>)
                    .then((res: Request) => {
                        console.log(res.data);
                    })
                    .catch((err: Error) => {
                        console.error(err);
                    })
                    .finally(() => {
                        console.log('done');
                    });
            },
            addAlias(user: any): void {
                type Alias = {
                    firstname: any;
                    lastname: any;
                    email: any;
                    point_code: any;
                };

                let alias: Alias = {
                    firstname: user.firstname,
                    lastname: user.lastname,
                    email: user.email,
                    point_code: this.selectedPos,
                };

                (this.apiStore.post('/user/alias', alias, null) as Promise<Request>)
                    .then((res: Request) => {
                        console.log(res.data);
                    })
                    .catch((err: Error) => {
                        console.error(err);
                    })
                    .finally(() => {
                        console.log('done');
                    });

                return user;
            },
        },
    });
</script>

<style></style>
