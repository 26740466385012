<template>
    <div class="users-list">
        <div class="user-main__header">
            <h1 class="fsb-typo-heading-1">{{ t('Points de Vente') }}</h1>
            <dynamic-table
                with-actions-column
                :fields="formattedFields"
                :items="posDetails"
                :unique-key="'point_code'"
                @item-clicked="handleRowClick"
                :enableCheckbox="false"
                :per-page="tableRowsPerPage[0].value"
            >
                <template #actions="item">
                    <icon-button icon-type="chevron-right" variant="white" @click="() => iconClicked(item)" />
                </template>
            </dynamic-table>
        </div>
        <pos-activation v-if="showModal" v-model:show-modal="showModal" :brand="brand" :pos-list="formattedInactiveValue" @close-modal="onCloseModal" :allow-close="true"></pos-activation>

        <div v-for="(item, index) in posDetails" :key="`tooltip-pos-${item.index}`">
            <base-tooltip :target="`subRoleTooltipTarget${index}`" placement="bottom">
                <div class="text-tooltip fsb-typo-para-regular">{{ t('notAllowedToActivateSubscription') }}</div>
            </base-tooltip>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed, ref, onMounted, inject } from 'vue';
    import { useApiStore } from '@/store/store-index';
    import { useI18n } from 'vue-i18n';
    import { useRouter } from 'vue-router';
    import { storeToRefs } from 'pinia';
    import { Ref } from 'vue';
    import { User } from '@adcleek/db-types';
    import SubscriptionStatus from '@/components/subscriptionStatus.vue';

    const snack: any = inject('snackBar');

    const { t } = useI18n({ useScope: 'global' });

    const tableLinesLabel = ref([
        { label: '25 ' + t('lines'), value: 25 },
        { label: '50 ' + t('lines'), value: 50 },
        { label: '75 ' + t('lines'), value: 75 },
        { label: '100 ' + t('lines'), value: 100 },
    ]);
    const apiStore = useApiStore();
    const { posDetails } = storeToRefs(apiStore);
    const router = useRouter();
    const tableRowsPerPage = ref([tableLinesLabel.value[0]]);

    const iconClicked = (item: any) => {
        router.push({ name: 'posDetail', params: { pointCode: item.item.point_code } });
    };

    const handleRowClick = (index: number, item: any) => {
        router.push({ name: 'posDetail', params: { pointCode: item.point_code } });
    };

    onMounted(() => {
        apiStore.fetchMandateStatus();
    });

    const formattedFields = computed(() => [
        { key: 'point_code', label: t('real_point_code'), sortable: true },
        { key: 'brand', label: t('brand'), sortable: true },
        { key: 'point_name', label: t('posName'), sortable: true },
        {
            key: 'mandate.mandate_statusText',
            label: t('Mandat Publicitaire'),
            sortable: true,
            getContent: (item: { mandate: { mandate_status: any; validity: any } }) => {
                if (item?.mandate?.mandate_status) {
                    return {
                        name: 'mandate-tag',
                        props: {
                            mandateStatus: item?.mandate?.mandate_status,
                            validity: item?.mandate?.validity,
                        },
                    };
                } else {
                    const label = t('notCreated');
                    return {
                        name: 'mandate-tag',
                        props: {
                            label: label,
                            variant: 'gray',
                        },
                    };
                }
            },
        },
        //Commented out because the add comptable option is not functional at the moment
        // {
        //     key: 'comptable.email',
        //     label: 'Comptable',
        //     sortable: true,
        //     getContent: (item: { comptable: { email: any } }) => {
        //         if (!item?.comptable?.email) {
        //             return {
        //                 name: 'ui-button',
        //                 props: {
        //                     label: 'Ajouter',
        //                     variant: 'secondary',
        //                     small: true,
        //                     rightIcon: 'add',
        //                 },
        //             };
        //         }
        //         return null;
        //     },
        // },
        {
            key: 'subscription',
            label: t('Abonnement'),
            sortable: true,
            getContent: (item: { subscription: any; point_code: string; createdAt?: string; brand: string; index: number }) => {
                if (item.subscription === null) {
                    const userRole = apiStore.userRole(item.brand);
                    const isSubRole = userRole !== 1;

                    return {
                        name: 'ui-button',
                        props: {
                            label: "Activer l'abonnement",
                            variant: 'secondary',
                            disabled: isSubRole,
                            style: 'pointer-events: auto;',
                            id: isSubRole ? 'subRoleTooltipTarget' + item.index : '',
                            small: true,
                            loadSpinner: activatingPos.value.includes(item.point_code),
                            onClick: () => {
                                if (!isSubRole) {
                                    if (activatingPos.value.includes(item.point_code)) return;
                                    setPosToActivate(item);
                                }
                            },
                        },
                    };
                } else {
                    return {
                        name: 'subscription-status',
                        props: {
                            active: true,
                            date: item.createdAt,
                        },
                    };
                }
            },
        },
    ]);

    const showModal = ref(false);
    const posToActivate: Ref<any> = ref({});
    const brand = ref(null);
    const activatingPos: Ref<string[]> = ref([]);
    const posSubscriptionAmount: Ref<User.PosActivationPriceCtl | null> = ref(null);

    const formattedInactiveValue = computed(() => {
        return [
            {
                name: posToActivate.value.point_name,
                city: posToActivate.value.city,
                code: posToActivate.value.point_code,
                ...posSubscriptionAmount.value,
            },
        ];
    });

    const setPosToActivate = async (pos: any) => {
        brand.value = pos.brand;
        posToActivate.value = pos;
        // formattedInactiveValue = [pos];
        posSubscriptionAmount.value = (await apiStore.get(`/user/pos-activation-price/${brand.value}/${pos.point_code}`)).data;
        showModal.value = true;
    };

    const onCloseModal = async (activationCount: number) => {
        showModal.value = false;
        if (activationCount === 0) return;
        snack.open('success', t('posActivated'), 3.6);
        const posActivating = posToActivate.value.point_code;
        activatingPos.value.push(posActivating);
        await apiStore.setPosDetails();
        const index = activatingPos.value.indexOf(posActivating);
        activatingPos.value.splice(index, 1);
    };
</script>

<style lang="scss">
    table th,
    table td {
        vertical-align: baseline !important;
    }

    .users-list {
        &__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;

            &__first,
            &__second {
                display: flex;
                gap: 16px;
            }
        }

        .fsb-typo-heading-1 {
            padding-bottom: 24px;
        }
        .linesFilter {
            > div {
                width: 100px !important;
                cursor: pointer;
                &.fsb-dropdown {
                    overflow: hidden !important;
                }
            }
        }

        .groupFilter {
            > div {
                width: 200px !important;
                cursor: pointer;
                &.fsb-dropdown {
                    overflow: hidden !important;
                }
            }
        }
    }
</style>
