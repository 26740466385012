<template>
    <div class="footer">
        <p class="footer-title text-center text-muted">© 2024 Adcleek</p>
        <ul class="footer-links justify-content-center">
            <li class="footer-item">
                <a :href="legalMentionPdf" class="footer-link text-muted" target="_blank" rel="noopener noreferrer" :download="getFilename(legalMentionPdf)">Mentions légales</a>
            </li>
            <span class="separator">-</span>
            <li class="footer-item">
                <a :href="cgauPdf" class="footer-link text-muted" target="_blank" rel="noopener noreferrer" :download="getFilename(cgauPdf)">Conditions générales d’abonnement et d’utilisation</a>
            </li>
            <span class="separator">-</span>
            <li class="footer-item">
                <a :href="confidialityPdf" class="footer-link text-muted" target="_blank" rel="noopener noreferrer" :download="getFilename(confidialityPdf)">Politique de confidentialité</a>
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
    const legalMentionPdf: string = process.env.VUE_APP_LEGAL_MENTION as string;
    const cgauPdf: string = process.env.VUE_APP_CGAU as string;
    const confidialityPdf: string = process.env.VUE_APP_CONFIDENTIALITY as string;

    const getFilename = (url: string) => {
        return url?.substring(url.lastIndexOf('/') + 1);
    };
</script>

<style scoped lang="scss">
    .footer {
        padding-top: 20px !important;
    }

    .footer-title {
        margin-bottom: 5px !important;
    }

    .footer-links {
        padding-bottom: 10px !important;
        margin-bottom: 10px !important;
        list-style: none;
        padding: 0;
        display: flex;
        align-items: center;
    }

    .footer-item {
        display: inline;
    }

    .footer-link {
        text-decoration: none;
    }

    .footer-link:hover {
        text-decoration: underline;
    }

    .separator {
        margin: 0 5px;
        color: #6c757d;
        vertical-align: middle;
        display: inline-block;
    }
</style>
